import * as React from "react"
import Layout from "../components/layout";
import * as Container from "../components/container/container";

const IndexPage = (props) => {
    return (
        <Layout pageTitle="OdD" props={props}>
            <main id="main">
                <Container.HeaderPart background="white no-image">
                    <div className="headline">
                        <div className="container">
                            <div className="row">
                                <div className="col-12">
                                    <h1>Der Vorsitzende des Allgemeinen Deutschen Gewerkschaftsbunds, Carl Legien
                                        (1861–1920), 1919</h1>
                                </div>
                            </div>
                        </div>
                    </div>
                </Container.HeaderPart>
                <Container.Part background="white">
                    <Container.Row>
                        <div className="col-6">
                            <figure className="lightbox">
                                <a className="glightbox" data-gallery="gallery2"
                                   href={"/images/Nuernberg/nuernberg_bild_0008.jpg"}
                                   data-glightbox="description: .custom-desc2_2; descPosition: bottom;">
                                    <img src={"/images/Nuernberg/nuernberg_bild_0008.jpg"}
                                         alt="Der Vorsitzende des Allgemeinen Deutschen Gewerkschaftsbunds, Carl Legien (1861–1920), 1919"/>
                                </a>
                                <div className="glightbox-desc custom-desc2_2">
                                    <h2>Der Vorsitzende des Allgemeinen Deutschen Gewerkschaftsbunds, Carl Legien
                                        (1861–1920), 1919</h2>
                                    <Container.If env="t">
                                        <p>
                                            Carl Legien zählte ab 1890 zu den führenden Köpfen der Gewerkschaftsbewegung.
                                            Bis zu seinem Tod 1920 war er der Vorsitzende des ADGB.
                                        </p>
                                        <p className="copy">Abb. Archiv der sozialen Demokratie der
                                            Friedrich-Ebert-Stiftung, 6/FOTA038420</p>
                                    </Container.If>
                                    <Container.If env="w">
                                        <p>Carl Legien engagierte sich ab 1885 in der Sozialdemokratischen Partei und
                                            schloss sich der Gewerkschaftsbewegung an. Seit er 1890 zum Vorsitzenden der
                                            „Generalkommission der Gewerkschaften Deutschlands“ gewählt wurde, ist sein Name
                                            fest mit der Geschichte der freien Gewerkschaften verknüpft. Gemeinsam mit
                                            seiner Lebensgefährtin Emma Ihrer bemühte er sich auch um den internationalen
                                            Austausch mit anderen Gewerkschaften. Ab 1893 gehörte er als Abgeordneter dem
                                            Reichstag an. 1918 handelte er als Führer der Gewerkschaften mit den
                                            Arbeitergeberverbänden im sogenannten Stinnes-Legien-Abkommen die Anerkennung
                                            der Gewerkschaften als Vertretung der Arbeiterschaft aus. Nach seinem Tod 1920
                                            übernahm Theodor Leipart seine Position als Vorsitzender des ADGB.</p>
                                        <p className="copy">Abb. Archiv der sozialen Demokratie der
                                            Friedrich-Ebert-Stiftung, 6/FOTA038420</p>
                                    </Container.If>
                                </div>
                                <figcaption>
                                    Der Vorsitzende des Allgemeinen Deutschen Gewerkschaftsbunds, Carl Legien (1861–1920),
                                    1919
                                    <span className="description">Carl Legien zählte seit 1890 zu den führenden Köpfen der Gewerkschaftsbewegung.
                                    Bis zu seinem Tod 1920 war er der Vorsitzende des ADGB.</span>
                                </figcaption>
                            </figure>
                            <br/>
                        </div>
                        <div className="col-6">
                            <p>Carl Legien engagierte sich ab 1885 in der Sozialdemokratischen Partei und
                                schloss sich der Gewerkschaftsbewegung an. Seit er 1890 zum Vorsitzenden der
                                „Generalkommission der Gewerkschaften Deutschlands“ gewählt wurde, ist sein Name
                                fest mit der Geschichte der freien Gewerkschaften verknüpft. Gemeinsam mit
                                seiner Lebensgefährtin Emma Ihrer bemühte er sich auch um den internationalen
                                Austausch mit anderen Gewerkschaften. Ab 1893 gehörte er als Abgeordneter dem
                                Reichstag an. 1918 handelte er als Führer der Gewerkschaften mit den
                                Arbeitergeberverbänden im sogenannten Stinnes-Legien-Abkommen die Anerkennung
                                der Gewerkschaften als Vertretung der Arbeiterschaft aus. Nach seinem Tod 1920
                                übernahm Theodor Leipart seine Position als Vorsitzender des ADGB.
                            </p>
                        </div>
                    </Container.Row>
                </Container.Part>
            </main>
        </Layout>
    )
}

export default IndexPage
